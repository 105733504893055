<template>
  <div>
    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
          <td
            align="center"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(50, 50, 93); text-align: center; font-size: 24px; line-height: 32px"
          >
            <v-img
              :to="{ name: 'home' }"
              :src="assets.logo.logoFull"
              max-width="150"
              max-height="150"
              contain
              class="mx-auto my-8"
              eager
            ></v-img>
          </td>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="6"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>

    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
          <td
            align="center"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(50, 50, 93); text-align: center; font-size: 24px; line-height: 32px"
          >
            Receipt from Red Sparrow
          </td>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="6"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>

    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
          <td
            align="center"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; width: 472px; vertical-align: middle; color: rgb(136, 152, 170); font-size: 15px; line-height: 18px"
          >
            Receipt ID: {{ receipt.transaction._id }}
          </td>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="24"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>

    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>

          <td valign="top" style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
            <table style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
              <tbody>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(136, 152, 170); font-size: 12px; line-height: 16px; white-space: nowrap; font-weight: bold; text-transform: uppercase"
                  >
                    AMOUNT PAID
                  </td>
                </tr>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px; white-space: nowrap"
                  >
                    <span style="color:rgb(136, 152, 170)"><span style="text-transform:uppercase">AUD</span></span
                    >${{ Number(receipt.amount).toFixed(2) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>

          <td
            width="20"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>

          <td valign="top" style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
            <table style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
              <tbody>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(136, 152, 170); font-size: 12px; line-height: 16px; white-space: nowrap; font-weight: bold; text-transform: uppercase"
                  >
                    DATE PAID
                  </td>
                </tr>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px; white-space: nowrap"
                  >
                    {{ new Date(receipt.transaction.created).toDateString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>

          <td
            width="20"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>

          <td valign="top" style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
            <table style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px">
              <tbody>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(136, 152, 170); font-size: 12px; line-height: 16px; white-space: nowrap; font-weight: bold; text-transform: uppercase"
                  >
                    PAYMENT METHOD
                  </td>
                </tr>
                <tr>
                  <td
                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px; white-space: nowrap"
                  >
                    <span
                      style="border: 0px !important; margin: 0px !important; outline: 0px !important; padding: 0px !important; text-decoration: none !important"
                      ><span style="text-transform:uppercase">{{
                        receipt.transaction.transactionData.payment_method_details.card.brand
                      }}</span
                      ><span>&nbsp;</span></span
                    ><span
                      style="border: 0px !important; margin: 0px !important; outline: 0px !important; padding: 0px !important; text-decoration: none !important"
                    >
                      - {{ receipt.transaction.transactionData.source.last4 }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>

          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="24"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>

    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td width="64" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
          <td
            style="border: 0px; margin: 0px; padding: 0px; color: rgb(136, 152, 170); font-size: 12px; line-height: 16px; text-transform: uppercase"
          >
            <span
              style="border: 0px; margin: 0px; outline: 0px !important; padding: 0px; text-decoration: none !important; font-weight: bold"
              >SUMMARY</span
            >
          </td>
          <td width="64" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
        </tr>
      </tbody>
    </table>
    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td colspan="3" height="4" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td width="64" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
          <td style="border: 0px; margin: 0px; padding: 0px">
            <table
              bgcolor="#f6f9fc"
              style="border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px"
            >
              <tbody>
                <tr>
                  <td style="border: 0px; margin: 0px; padding: 0px">
                    <table>
                      <tbody>
                        <tr>
                          <td
                            colspan="3"
                            height="24"
                            style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px"
                          >
                            <div>&nbsp;</div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="16"
                            style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px"
                          >
                            <div>&nbsp;</div>
                          </td>
                          <td
                            style="border: 0px; margin: 0px; padding: 0px; color: rgb(82, 95, 127); font-size: 16px; line-height: 24px"
                          >
                            <table style="padding-left: 5px; padding-right: 5px">
                              <tbody>
                                <tr>
                                  <td
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px; width: 357.4375px"
                                  >
                                    {{ receipt.transaction.title }}
                                  </td>
                                  <td
                                    width="8"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    align="right"
                                    valign="top"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px"
                                  >
                                    <span style="text-transform:uppercase">{{ receipt.transaction.currency }}$</span
                                    >{{ Number(receipt.transaction.amount / 100).toFixed(2) }}
                                  </td>
                                </tr>
                                <!-- <tr>
                                                            <td style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 12px; line-height: 16px; width: 357.4375px">{{receipt.transaction.data.paymentDetails.nameOnCard}}</td> 
                                                            <td width="8" style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px">&nbsp;</td>
                                                            <td align="right" valign="top" style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 12px; line-height: 24px">{{receipt.transaction.data.paymentDetails.email}}</td>
                                                        </tr> -->
                                <tr>
                                  <td
                                    colspan="3"
                                    height="6"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="3"
                                    height="6"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    bgcolor="e6ebf1"
                                    colspan="3"
                                    height="1"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="3"
                                    height="8"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px; width: 357.4375px"
                                  >
                                    <strong>Amount charged</strong>
                                  </td>
                                  <td
                                    width="8"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    align="right"
                                    valign="top"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; vertical-align: middle; color: rgb(82, 95, 127); font-size: 15px; line-height: 24px"
                                  >
                                    <strong
                                      ><span style="text-transform:uppercase">{{ receipt.transaction.currency }}</span
                                      >${{ Number(receipt.transaction.amount / 100).toFixed(2) }}</strong
                                    >
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colspan="3"
                                    height="6"
                                    style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            width="16"
                            style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px"
                          >
                            <div>&nbsp;</div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="3"
                            height="24"
                            style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px"
                          >
                            <div>&nbsp;</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width="64" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="24"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>
    <table :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 600px' : 'min-width: 350px'">
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            <div>&nbsp;</div>
          </td>
          <td
            align="center"
            style="border: 0px; margin: 0px; padding: 0px; font-size: 12px; line-height: 24px; color: rgb(82, 95, 127) !important; vertical-align: middle"
          >
            If you have any questions, contact us at<br />
            <!--Update link to Library email form with Domain is finalised-->
            <a
              href="https://redsparrowbooks.tendril.com.au/contact-us"
              style="border: 0px; margin: 0px; outline: 0px !important; padding: 0px; text-decoration: none; color: rgb(85, 108, 214) !important"
              target="_blank"
              >https://redsparrowbooks.tendril.com.au/contact-us</a
            >.
          </td>
          <td width="64" style="border: 0px; margin: 0px; padding: 0px; font-size: 1px; line-height: 1px">
            <div>&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="24"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>

    <table
      style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; background-color: rgb(255, 255, 255); vertical-align: middle"
    >
      <tbody>
        <tr>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
          <td
            align="center"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; width: 472px; vertical-align: middle; color: rgb(136, 152, 170); font-size: 12px; line-height: 16px"
          >
            You're receiving this email because you made a purchase with Red Sparrow, which partners with
            <span>
              &nbsp;
            </span>
            <a
              rel="noreferrer"
              href="https://stripe.com/au"
              style="border: 0px; margin: 0px; outline: 0px !important; padding: 0px; text-decoration: none; color: rgb(85, 108, 214)"
              target="_blank"
              >Stripe</a
            >
            <span>&nbsp;</span>
            to provide invoicing and payment processing.
          </td>
          <td
            width="64"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px; vertical-align: middle"
          >
            &nbsp;
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            height="24"
            style="border: 0px; border-collapse: collapse; margin: 0px; padding: 0px; color: rgb(255, 255, 255); font-size: 1px; line-height: 1px"
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["receipt"],
  computed: {
    ...mapState(["assets"]),
  },
};
</script>

<style scoped lang="scss"></style>
