<template>
    <v-responsive>
        <div class="constraint sm">
            <!-- Payment Form -->
            <template v-if="!paymentComplete">
                <div class="intro-center-text">
                    <h1>Get Full Access</h1>
                    <p class="h4">
                        Become a Red Sparrow member today<br />
                        and get access to great features! Including:
                    </p>
                </div>

                <v-row class="feature-icons">
                    <v-col>
                        <v-icon size="60">$BookStack</v-icon>
                        <p class="ma-0 py-3">Full Library</p>
                    </v-col>

                    <v-col
                        ><v-icon class="" size="60">fa-calendar-plus-o</v-icon>
                        <p class="ma-0 py-3">Unlimited Schedule Creation</p>
                    </v-col>
                    <v-col
                        ><v-icon class="" size="60">mdi-image-multiple</v-icon>
                        <p class="ma-0 py-3">Library of Images</p></v-col
                    >
                </v-row>

                <form @submit.prevent="getCard" id="stripeForm">
                    <!-- Login Details (if user is not signed in)-->
                    <div class="box-panel" v-if="!$app.user">
                        <h3>Your Details</h3>
                        <p>Setup your login details</p>
                        <v-divider />
                        <sign-up-form
                            ref="signupForm"
                            isPaymentForm
                            paymentTitle="Sign Up Details"
                            @signupError="handleSignupError"
                            @signupSuccess="handleSignupSuccess"
                        ></sign-up-form>
                    </div>

                    <div class="box-panel" @click="resetErrorMessages">
                        <!-- Product Selection -->
                        <v-row>
                            <v-col col="12">
                                <h3>Membership Subscriptions</h3>
                                <p class="mb-0">Choose a plan</p>
                                <!-- <v-divider  /> -->
                            </v-col>
                        </v-row>

                        <v-row class="text-center">
                            <v-col v-for="product in products" :key="product.price" class="d-flex"
                                ><v-btn
                                    block
                                    @click="changeProduct(product)"
                                    :outlined="productSelect != product.index ? true : false"
                                    rounded
                                    elevation="0"
                                    class="product-button mx-auto"
                                    :class="productSelect != product.index ? `outline muted` : `active-product`"
                                    :disabled="toggleRedemption"
                                >
                                    {{ product.title }}

                                    ${{ product.price }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-checkbox v-model="recurring" :disabled="toggleRedemption" label="Auto Renewal"></v-checkbox>

                        <v-row dense>
                            <v-col cols="10">
                                <div v-if="!toggleRedemption">
                                    Have a redemption code? <a @click="toggleRedemption = true">redeem here!</a>
                                </div>
                                <div v-else>
                                    <v-btn
                                        class="pa-0"
                                        :ripple="false"
                                        text
                                        @click="
                                            toggleRedemption = false;
                                            redeemCode = null;
                                            resetRedeem();
                                        "
                                        ><v-icon left>mdi-chevron-left</v-icon>Back</v-btn
                                    >
                                </div>
                            </v-col>
                            <v-spacer />
                            <!-- <v-col cols="2" class="mx-auto" v-if="toggleRedemption == true">
                                    <v-btn block small :loading="validatingRedeemCode" @click="toggleRedemption = false">Back</v-btn>
                                </v-col> -->
                        </v-row>
                        <v-container v-if="toggleRedemption">
                            <v-row dense class="d-flex align-center">
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="redeemCode"
                                        :disabled="validatingRedeemCode"
                                        required
                                        label="Redeem Code"
                                        class="pt-0 my-5 white-label white-button white-input white-text"
                                        outlined
                                        hide-details="auto"
                                        @focus="redeemErrorMsg = null"
                                        @input="debounceValidateCode"
                                        @paste="(e) => validateCode(e)"
                                        :loading="validatingRedeemCode"
                                        @change="resetRedeem"
                                    >
                                    </v-text-field>
                                    <div v-if="redeemErrorMsg" class="error--text">{{ redeemErrorMsg }}</div>
                                </v-col>
                                <!-- <v-col cols="4" class="my-4 mx-auto product-button">
                                    <v-btn block x-large :loading="validatingRedeemCode" @click="validateCode">Validate</v-btn>
                                </v-col> -->
                            </v-row>

                            <v-row v-if="validRedemptionCode" dense>
                                <v-col>
                                    <div>
                                        <p style="font-size: larger" class="success--text text--darken-1">
                                            Redeem Code - Free {{ validRedemptionCode.title }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-row dense v-else>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="card.cardName"
                                    :disabled="loading"
                                    required
                                    label="Card Holder"
                                    :rules="[rules.required]"
                                    class="pt-0 my-5 white-label white-button white-input white-text"
                                    placeholder="John Smith"
                                >
                                </v-text-field>
                            </v-col>
                            <!-- <template v-if="recurring">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="address.city"
                :disabled="loading"
                required
                label="City"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder="Melbourne"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="address.line1"
                :disabled="loading"
                required
                label="Address Line 1"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder=""
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="address.line2"
                :disabled="loading"
                required
                label="Address Line 2"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder=""
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="address.postal_code"
                :disabled="loading"
                required
                label="Postal Code"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder="3000"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="address.state"
                :disabled="loading"
                required
                label="State"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder="VIC"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="email"
                :disabled="loading"
                required
                label="Email"
                :rules="[rules.required]"
                
                class="pt-0 my-5 white-label white-button white-input white-text"
                placeholder="something@gmail.com"
              >
              </v-text-field>
            </v-col>
          </template> -->
                            <v-col cols="12">
                                <v-label class="">Card Number</v-label>
                                <div id="stripe-card-number" class="field empty"></div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Expiry Date</v-label>
                                <div id="stripe-card-expiry" class="field empty"></div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Security Code</v-label>
                                <div id="stripe-card-cvc" class="field empty"></div>
                            </v-col>
                        </v-row>
                    </div>
                </form>
            </template>

            <!-- Success Message -->
            <template v-else>
                <v-card v-if="$vuetify.breakpoint.mdAndUp" class="receipt-card mx-auto" width="600px">
                    <Receipt :receipt="receipt"></Receipt>
                </v-card>

                <v-card v-else class="receipt-card mx-auto" width="350px">
                    <Receipt :receipt="receipt"></Receipt>
                </v-card>
                <!-- Need extra space to push the footer down -->
                <v-sheet height="250" color="transparent"></v-sheet>
            </template>

            <div id="card-errors"></div>
            <template v-if="!paymentComplete && !validRedemptionCode">
                <!-- <v-divider > </v-divider> -->

                <!-- Payment Info -->
                <div class="text-center" style="font-size: 1.2rem">
                    <v-row>
                        <v-col cols="12">
                            <strong class="">{{ products[productSelect].title }} - Red Sparrow Books</strong>
                        </v-col>
                        <v-col>
                            <p>Subtotal</p>
                        </v-col>
                        <v-col>
                            <p>${{ products[productSelect].price }} {{ products[productSelect].curency }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>Discount</p>
                        </v-col>
                        <v-col>
                            <p>0%</p>
                        </v-col>
                    </v-row>
                </div>
                <v-divider> </v-divider>
                <div class="py-8 text-center" style="font-size: 1.2rem">
                    <v-row>
                        <v-col>
                            <strong>Total</strong>
                        </v-col>
                        <v-col>
                            <strong>${{ products[productSelect].price }} {{ products[productSelect].curency }}</strong>
                        </v-col>
                    </v-row>
                </div>

                <!-- Payment Actions -->
                <div>
                    <v-row class="ma-0 d-flex align-center justify-center">
                        <v-col cols="12" v-show="errorMessage">
                            <div class="mx-auto text-center red--text text--darken-1">{{ errorMessage }}</div>
                        </v-col>
                        <!-- <v-col cols="6"> -->
                        <!-- <v-btn block @click="reset" x-large  outlined :disabled="loading">Clear</v-btn> -->
                        <!-- </v-col> -->

                        <v-col cols="7" class="my-4 mx-auto">
                            <v-btn block x-large type="submit" form="stripeForm" :loading="loading"
                                >${{ products[productSelect].price }} {{ products[productSelect].currency }} - Confirm Purchase</v-btn
                            >
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-if="!paymentComplete && validRedemptionCode">
                <!--REDEEM Payment Info -->
                <div class="text-center" style="font-size: 1.2rem">
                    <v-row>
                        <v-col cols="12">
                            <strong class="">{{ products[productSelect].title }} - Red Sparrow Books</strong>
                        </v-col>
                        <v-col>
                            <p>Subtotal</p>
                        </v-col>
                        <v-col>
                            <p :class="{ 'text-decoration-line-through': validRedemptionCode }">
                                ${{ products[productSelect].price }} {{ products[productSelect].currency }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>Discount</p>
                        </v-col>
                        <v-col>
                            <p>100%</p>
                        </v-col>
                    </v-row>
                </div>
                <v-divider> </v-divider>
                <div class="py-8 text-center" style="font-size: 1.2rem">
                    <v-row>
                        <v-col>
                            <strong>Total</strong>
                        </v-col>
                        <v-col>
                            <strong>$0 {{ products[productSelect].currency }}</strong>
                        </v-col>
                    </v-row>
                </div>

                <!-- Payment Actions -->
                <div>
                    <v-row>
                        <v-col cols="12" v-show="confirmRedeemErrorMsg">
                            <div class="mx-auto text-center red--text text--darken-1">{{ confirmRedeemErrorMsg }}</div>
                        </v-col>
                        <v-col cols="4" class="my-4 mx-auto">
                            <v-btn block x-large :loading="loading" @click="confirmRedeem">Redeem Code</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </div>

        <AlertDialog ref="redeemSuccess" title="Redeem Code Activated!" hideCancel @confirm="$refs.redeemSuccess.close()"></AlertDialog>
    </v-responsive>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import Receipt from "./receipt.vue";
import AlertDialog from "./AlertDialog.vue";
import SignUpForm from "@/router/routes/user/signup.vue";

import moment from "moment";

const ANNUAL_SUBSCRIPTION = "641a756e82c6a4039eb70811";
const MONTHLY_SUBSCRIPTION = "641a75c582c6a4039eb7081b";

export default {
    components: { Receipt, SignUpForm, AlertDialog },
    data() {
        return {
            token: null,
            cardDetails: null,

            email: null,
            recurring: true,
            address: {
                city: null,
                country: "Australia",
                line1: null,
                line2: null,
                postal_code: null,
                state: null,
            },
            productSelect: 0,
            products: [
                { title: "Annual subscription", price: 84.0, index: 0 },
                { title: "Monthly subscription", price: 12.0, index: 1 },
            ],
            receipt: null,
            cardError: false,
            successMessage: null,
            errorMessage: null,
            paymentComplete: false,
            stripe: "",
            card: { cardName: "" },
            loading: false,
            rules: {
                required: (value) => !!value || "This field is required",
            },

            redeemCode: "",
            toggleRedemption: false,
            validatingRedeemCode: false,
            redeemErrorMsg: "",
            validRedemptionCode: null,
            confirmRedeemErrorMsg: "",

            debounceTimer: null,
        };
    },

    methods: {
        async getPurchases() {
            //Mongo params to pass into fluro
            const query = {
                _type: "purchase",
                status: "active",
                // managedPurchaser: this.$app.user.persona,
                "data.personaId": this.$app.user.persona,
            };

            const purchases = await this.$fluro.api
                .post("/content/_query", query, {
                    params: {
                        noCache: true,
                    },
                })
                .then(({ data }) => {
                    // console.log(data);
                    return data;
                })
                .catch((err) => console.log(err));
            return purchases;
        },

        async redeem(code) {
            const redeem = await this.$fluro.api.post("/redeem", {
                recipient: this.$app.user.persona,
                redemptionCode: code,
            });
            return redeem;
        },

        async updateExpiryDate(purchase) {
            let expiryDate = new Date();
            expiryDate.setHours(0, 0, 0, 0);
            if (purchase.product?._id == ANNUAL_SUBSCRIPTION) expiryDate.setFullYear(expiryDate.getFullYear() + 1);
            else if (purchase.product?._id == MONTHLY_SUBSCRIPTION) expiryDate.setMonth(expiryDate.getMonth() + 1);
            else {
                throw new Error("Unable to update redeemed purchase");
            }

            let payload = {
                purchaseId: purchase?._id,
                expiryDate: expiryDate,
            };

            return await this.$fluro.api
                .post("/reaction/spark/6552eccc8ff3f6715269e282", payload)
                .then((res) => {
                    // console.warn("Reaction Updated expiry date:", res);
                    return res;
                })
                .catch((err) => {
                    console.log("ERROR", err);
                    throw err;
                });
        },

        async confirmRedeem() {
            this.loading = true;
            try {
                let redeemedPurchase = await this.redeem(this.redeemCode).then(({ data }) => data);
                // console.log("Redeemed PUrchase:", redeemedPurchase);

                //Only Update the expiry date, if the user is the First license user.
                const updateExpiry = redeemedPurchase?.managedLicense?.length === 1;
                // console.warn("updateExpiry:", updateExpiry);

                if (updateExpiry) {
                    redeemedPurchase = await this.updateExpiryDate(redeemedPurchase).then(({ data }) => data);
                }

                //Refresh user
                this.$fluro.auth.sync();

                //Successful Redeem Dialog
                if (redeemedPurchase?._id && redeemedPurchase?.title) {
                    this.$refs.redeemSuccess.open(
                        redeemedPurchase,
                        `<b>${redeemedPurchase?.title}</b><br/><p>Expires: ${moment(redeemedPurchase?.expiryDate).format(
                            "dddd, MMMM Do YYYY"
                        )}</p>`
                    );
                }
            } catch (error) {
                console.log("Redeem Error:", error?.response?.data);
                this.confirmRedeemErrorMsg =
                    error?.response?.data || "Redeem code failed. Please try again later or contact Red Sparrow For Autism.";
            }
            this.loading = false;
        },

        debounceValidateCode() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.validateCode();
            }, 2000);
        },
        async validateCode(event) {
            if (event) {
                event.preventDefault();
                const clipboardData = event.clipboardData;
                const pastedData = clipboardData.getData("text");
                this.redeemCode = pastedData;
            }

            // this.resetRedeem();
            console.warn("Validate Code INVOKED!", this.redeemCode);
            if (this.redeemCode.length) {
                this.validatingRedeemCode = true;

                try {
                    this.validRedemptionCode = await this.$fluro.api
                        .post("/redeem/validate", {
                            redemptionCode: this.redeemCode,
                        })
                        .then(({ data }) => data?.product);
                    console.log("Valid Redemption Code:", this.validRedemptionCode);
                    switch (this.validRedemptionCode?._id) {
                        case ANNUAL_SUBSCRIPTION:
                            this.productSelect = 0;
                            break;
                        case MONTHLY_SUBSCRIPTION:
                            this.productSelect = 1;
                            break;
                        default:
                            this.validRedemptionCode = null;
                            throw new Error("Invalid Redeem Code.");
                    }
                } catch (error) {
                    console.log("Validate Code ERROR:", error?.response?.data);
                    this.redeemErrorMsg = "Redeem code failed. Please try again later or contact Red Sparrow For Autism.";
                }
                this.validatingRedeemCode = false;
            }
        },

        resetRedeem() {
            this.validRedemptionCode = null;
            this.redeemErrorMsg = null;
        },

        resetErrorMessages() {
            console.log("Reset Error Messages Fired!");
            this.errorMessage = null;
            this.redeemErrorMsg = null;
            this.confirmRedeemErrorMsg = null;
        },

        changeProduct(product) {
            //0 - Annual Subscription
            //1 - Monthly Subscription
            // console.log("PRODUCT CHANGE:", product);
            this.productSelect = product.index;
        },

        async getStripe() {
            //Initiate Stripe
            // Live Stripe Intergration
            //console.log("Checking if manager?:", this.$isManager(), this.$valita.user, this.stripe);
            //Red Sparrow Test Integration: 6417b2a950f0263514813d79
            //Red Sparrow Strip Integration (LIVE): 5964282cc4cece2ef2f7ed24

            let integration = await this.$fluro.api
                .get("/content/integration/5964282cc4cece2ef2f7ed24")
                .then(({ data }) => data)
                .catch((res) => {
                    console.log("Intergration Error:", res);
                });
            // console.log("Key", integration);
            // Appbooks Test Intergration
            // let integration = await this.$fluro.api
            //   .get("/content/integration/616794f4e3e69a24ffc9fe49")
            //   .then(({ data }) => data);

            if (integration) {
                let key = integration?.publicDetails?.livePublicKey;

                if (integration?.publicDetails?.sandbox) {
                    key = integration?.publicDetails?.testPublicKey;
                }
                this.stripe = window.Stripe(key);
            }
        },

        async createPaymentMethod(token) {
            // console.warn("about to create payment method:", token);
            // console.warn("attached to user:", this.$app?.user);
            if (token && this.$app?.user?.persona) {
                let createPayload = {
                    source: token,
                    // sandbox: true,
                    user: this.$app.user.persona,
                };
                console.log("about to create payment method:", createPayload);
                //TEST Integration: 6417b2a950f0263514813d79
                //LIVE Intergration: 5964282cc4cece2ef2f7ed24
                let methodId = await this.$fluro.api
                    .post("/payment/method/5964282cc4cece2ef2f7ed24", createPayload)
                    .then(({ data }) => {
                        // console.warn("Create Payment Methods:", data);
                        return data._id;
                    })
                    .catch((err) => console.log(err));
                return methodId;
            }
        },

        // async getSubscriptionProducts() {
        //     const query = {
        //         status: "active",
        //         _id: {
        //             $in: ["641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
        //         },
        //     };
        //     // {
        //     //         ids: ["641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
        //     //     }
        //     const products = await this.$fluro.api
        //         .post("/content/product/_query", query)
        //         .then(({ data }) => {
        //             return data;
        //         })
        //         .catch((err) => console.log(err));
        //     console.warn("Products:", products);

        //     let annual = await this.$fluro.content
        //         .get("641a756e82c6a4039eb70811")
        //         .then(({ data }) => {
        //             return data;
        //         })
        //         .catch((err) => console.log(err));

        //     console.warn("Anuual sub product:", annual);
        // },

        reset() {
            this.card = {};
        },
        async init() {
            await this.getStripe();
            // console.log(this.stripe);
            var elements = this.stripe.elements();
            var elementStyles = {
                base: {
                    color: "#000",
                    fontWeight: 500,
                    // fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                    fontSize: "1.2rem",
                    fontSmoothing: "antialiased",
                    "::placeholder": {
                        color: "#CCCCCC",
                    },
                    ":-webkit-autofill": {
                        color: "#e39f48",
                    },
                },
                invalid: {
                    color: "#E25950",
                    "::placeholder": {
                        color: "#FFCCA5",
                    },
                },
            };
            var elementClasses = {
                focus: "focused",
                empty: "empty",
                invalid: "invalid",
            };
            //create elements
            var cardNumber = elements.create("cardNumber", {
                showIcon: true,
                style: elementStyles,
                classes: elementClasses,
            });
            var cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses,
            });
            var cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses,
            });

            //mount elements
            cardNumber.mount("#stripe-card-number");
            cardExpiry.mount("#stripe-card-expiry");
            cardCvc.mount("#stripe-card-cvc");
            this.card = {
                cardNumber,
                cardExpiry,
                cardCvc,
            };
            // console.log("initialised", this.card);
        },
        async getCard() {
            this.loading = true;

            const CARD_NUMBER = document.getElementById("stripe-card-number");
            let cardNumberInvalid = CARD_NUMBER?.classList.contains("StripeElement--invalid");
            // console.log("CARD_NUMBER Invalid:", cardNumberInvalid);
            const CARD_EXPIRY = document.getElementById("stripe-card-expiry");
            let expiryInvalid = CARD_EXPIRY?.classList.contains("StripeElement--invalid");
            // console.log("CARD_EXPIRY Invalid:", expiryInvalid);
            const CARD_CVC = document.getElementById("stripe-card-cvc");
            let CVCInvalid = CARD_CVC?.classList.contains("StripeElement--invalid");
            // console.log("CARD_CVC Invalid:", CVCInvalid);
            if (cardNumberInvalid || expiryInvalid || CVCInvalid) {
                throw { message: "Invalid Card Details" };
            }
            this.errorMessage = null;

            await this.validateCardDetails(this.card);

            //If initial sign up (!this.$app.user)
            if (!this.$app.user && !this.errorMessage && this.token) {
                await this.$refs.signupForm.submit();
                //After sign up submit, if successful, fire this.handleSignupSuccess()
            } else if (this.$app.user && this.token) {
                await this.confirmPayment(this.token, this.cardDetails);
                this.loading = false;
            }

            return this.card;
        },
        async confirmPayment(token, cardDetails) {
            let methodId;

            if (this.recurring) {
                //If creatingpayment method, use paymentMethod for purchase (token will get used when creating paymentMethod)
                //CANNOT use stripe token more than once
                methodId = await this.createPaymentMethod(token);
                console.warn("Method before confirm:", methodId);
                token = null;
            }

            let payload = {
                paymentMethod: methodId,
                userDetails: this.$app.user,
                email: this.email,
                address: this.address,
                recurring: this.recurring,
                contact: this.$app.user.contacts[0],
                persona: this.$app.user.persona,
                title: this.products[this.productSelect].title,
                product: this.products[this.productSelect].index,
                token,
                paymentDetails: {
                    email: this.$app.user.email,
                    nameOnCard: cardDetails.nameOnCard,
                    cardExpiry: `${cardDetails.month}/${cardDetails.year}`,
                    last4: cardDetails.last4,
                },
                annualSubscriptionDetails: {
                    subscriptionStartDate: this.defaultStartDate,
                    subscriptionEndDate: this.productSelect == 0 ? this.endDateYear : this.endDateMonth,
                },
            };
            console.log(payload, "payload");
            // console.log(token, "token");

            if (token || methodId) {
                const response = await this.$fluro.api
                    .post("/reaction/spark/6417e56f6c1fb811a93b98d8", payload)
                    .then((res) => {
                        // console.warn("Reaction finihed:", res);
                        if (res.data && res.data?.transaction?._id) {
                            // console.log("Reaction Transaction Result:", res?.data?.transaction);
                            this.paymentComplete = true;
                            this.receipt = res.data;
                            this.successMessage = "Payment Successful";
                            // console.log("Reaction completed", res);
                            return res;
                        }
                        // else {
                        //   console.warn("Reaction ERROR 1:", res);
                        //   this.error = true;
                        //   this.errorMessage = res.data.result;
                        // }
                    })
                    .catch((err) => {
                        console.warn("Reaction ERROR 2:", err);
                        this.loading = false;
                        this.errorMessage = err.response.data.log[1].errorMessage;
                        console.log("ERROR", err);
                    });

                //Redeem New Purchase

                const purchases = await this.getPurchases();
                const newPurchase = purchases.pop();
                const code = newPurchase.redemptionCode;
                const redeemed = await this.redeem(code);
                console.log("Redeemed purchase is:", redeemed.data);
            }

            //Refresh store with premium access
            this.$fluro.auth.sync();
        },

        async validateCardDetails(card) {
            this.token = await this.stripe
                .createToken(card.cardNumber)
                .then((res) => {
                    // console.log("Stripe Token Res:", res);
                    if (res?.token) {
                        this.cardDetails = {
                            nameOnCard: card?.cardName,
                            month: res.token?.card.exp_month,
                            year: res.token?.card.exp_year,
                            last4: res.token?.card.last4,
                        };
                        // console.log("Successfully created Stripe Token", res);
                        return res?.token?.id;
                    } else {
                        this.error();
                        console.warn("Validate Card ERROR", res?.error);
                        this.errorMessage = res?.error?.message;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.error();
                });
        },
        error() {
            this.paymentComplete = false;
            this.loading = false;
            this.cardError = true;
        },
        handleSignupError({ errorMessage }) {
            console.warn("HANDLE SIGNUP ERROR:", errorMessage);
            this.errorMessage = errorMessage;
            this.error();
        },
        async handleSignupSuccess(newUser) {
            console.warn("New user (after sign from purchase form):", newUser);
            this.$app.user = newUser;
            await this.confirmPayment(this.token, this.cardDetails);
            this.loading = false;
        },

        async getProducts() {
            const query = {
                _type: "product",
                status: "active",
                _id: {
                    $in: ["641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
                },
            };

            return await this.$fluro.api
                .post("/content/_query", query, {
                    select: "amount currenecy",
                })
                .then(({ data }) => {
                    return data;
                })
                .catch((err) => console.log(err));
        },
    },
    computed: {
        ...mapState(["assets", "headerCache"]),

        endDateYear() {
            let endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            return endDate.toISOString();
        },
        endDateMonth() {
            let endDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
            return endDate.toISOString();
        },
        defaultStartDate() {
            let startDate = new Date();
            let localTimeOffset = startDate.getTimezoneOffset() * 60 * 1000;
            startDate = new Date(startDate - localTimeOffset);
            return startDate.toISOString();
        },
    },
    async mounted() {
        let products = await this.getProducts();
        // console.warn("products:", products);
        let monthlySubscription = products.find((item) => item._id == "641a75c582c6a4039eb7081b");
        let annualSubscription = products.find((item) => item._id == "641a756e82c6a4039eb70811");

        this.products[0] = {
            ...this.products[0],
            price: annualSubscription?.price / 100 || 84,
            currency: annualSubscription?.currency || "AUD",
        };
        this.products[1] = {
            ...this.products[1],
            price: monthlySubscription?.price / 100 || 12,
            currency: monthlySubscription?.currency || "AUD",
        };

        this.init();
    },
};
</script>
<style scoped lang="scss">
.product-button {
    margin: 0;
}

.box-panel {
    background: #fff;
    box-shadow: 0 2px 5px rgba(#000, 0.1);
    //padding: 1em;
    padding: 4%;
    border-radius: 1em;
    margin-bottom: 2em;
}

.intro-center-text {
    text-align: center;
    margin-bottom: 4em;
}

.feature-icons {
    margin-bottom: 4em;
    text-align: center;
}

/**
.active-product {
  scale: 1.1;
}
.inactive-product {
  scale: 0.9;
}
.receipt-card {
  width: 100%;
}

.full-width {
  width: 100%;
}

.theme--light.v-label {
  color: rgba(255, 255, 255);
}
.field {
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  border: 1px solid var(--v-primary-lighten2);
  transition: all 0.2s;
  &.focused {
    border: 2px solid var(--v-primary-base);
  }
  &.invalid {
    border: 2px solid var(--v-error-base);
  }
}
.__PrivateStripeElement {
  width: 100%;
}
.field + .field {
  margin-top: 6px;
}
input::-webkit-input-placeholder {
  color: #9bacc8;
}
input::-moz-placeholder {
  color: #9bacc8;
}
input:-ms-input-placeholder {
  color: #9bacc8;
}
.error svg .base {
  fill: #fa755a;
}
.error svg .glyph {
  fill: #fff;
}
.error .message {
  color: #fff;
}
.success .icon .border {
  stroke: #fcd669;
}
.success .icon .checkmark {
  stroke: #fff;
}
.success .title {
  color: #fff;
}
.success .message {
  color: #9cabc8;
}
.success .reset path {
  fill: #fff;
}
.width-full {
  width: 100%;
}
.white-label label {
  color: white !important;
  border-color: white;
}
.white-button button {
  color: white !important;
  border-color: white;
}

.white-text input {
  color: white !important;
  border-color: white;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: white !important;
  border-style: solid;
  border-width: thin 0 0 0;
}
/**/
</style>
