<template>
    <v-dialog v-model="display" :width="dialogWidth" overlay-opacity="0.1" content-class="rounded-xl" rounded="xl" persistent>
        <v-card>
            <v-card-title class="align-center justify-center px-5"
                ><h5>{{ title }}</h5></v-card-title
            >
            <v-card-text class="pa-5 text-center text-body-2" v-html="dialogMessage"></v-card-text>
            <v-card-actions class="pa-5">
                <v-btn
                    v-if="!this.showError && !this.hideCancel"
                    :disabled="!isValid"
                    :color="cancelColor"
                    class="full-width flex-shrink-1 flex-grow-1"
                    depressed
                    outlined
                    rounded
                    v-text="cancelLabel"
                    @click="cancel"
                />
                <v-btn
                    v-if="!this.showError && !this.hideAction"
                    :disabled="!isValid"
                    :color="actionColor"
                    class="full-width flex-shrink-1 flex-grow-1"
                    depressed
                    rounded
                    v-text="actionLabel"
                    @click="confirm"
                />
                <v-btn v-if="this.showError" color="primary" depressed block rounded v-text="'Close'" @click="cancel" />
            </v-card-actions>

            <v-overlay color="white" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
    props: {
        title: {
            type: String,
            default() {
                return "Alert";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        dialogWidth: {
            type: String,
            default() {
                return "500";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },
    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            model: null,
            message: "",
            errorMessage: "",
        };
    },
    computed: {
        isValid() {
            return !this.loading;
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || "<p>An error occured. Try again.</p>";
            return this.message || "<p>This is an alert.</p>";
        },
    },
    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = null;
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(model, message) {
            this.init(model);
            this.message = message;
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            console.log("Confirm CLICKED!", this.loading);
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
        },
    },
};
</script>
